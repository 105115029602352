/*------------------------------------------------------------------
 [Master Stylesheet]

  Project:	Star Admin Bootstrap Template [Free Version]
  Version:	2.0
-------------------------------------------------------------------*/



/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Background Mixins
    + BlockQuote Mixins
    + Badges Mixins
    + Buttons Mixins
    + Cards Mixins
    + Miscellaneous Mixins
    + Text Mixins
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Sidebar
    + Navbar
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
    + Dashboard
  * Components
    + Badges
    + Buttons
    + Cards
    + Checkboxes and Radios
    + Dropdowns
    + Forms
    + Icons
    + Preview
    + Tables
  * Landing screens
    + Auth
    + Error
-------------------------------------------------------------------*/


/*-------------------------------------------------------------------*/
/* === Import Bootstrap functions and variables === */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

/*-------------------------------------------------------------------*/
/* === Import template variables === */
@import "variables";

/*-------------------------------------------------------------------*/
/* === SCSS Compass Functions === */
@import "../node_modules/compass-mixins/lib/compass";
@import "../node_modules/compass-mixins/lib/animate";

/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
@import "../node_modules/bootstrap/scss/bootstrap";

/*-------------------------------------------------------------------*/
/* === Template mixins === */
@import "mixins/animation";
@import "mixins/background";
@import "mixins/blockqoute";
@import "mixins/badges";
@import "mixins/buttons";
@import "mixins/cards";
@import "mixins/misc";
@import "mixins/text";

/*-------------------------------------------------------------------*/
/* === Core Styles === */
@import "reset";
@import "fonts";
@import "functions";
@import "sidebar";
@import "navbar";
@import "typography";
@import "misc";
@import "footer";
@import "utilities";
@import "demo";
@import "dashboard";

/*-------------------------------------------------------------------*/
/* === Components === */
@import "components/badges";
@import "components/buttons";
@import "components/cards";
@import "components/checkbox-radio";
@import "components/dropdown";
@import "components/forms";
@import "components/icons";
@import "components/preview";
@import "components/tables";

/*-------------------------------------------------------------------*/
/* === Landing screens === */
@import "landing-screens/auth";
@import "landing-screens/error";