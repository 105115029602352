/* Tables */


.table {
  margin-bottom: 0;

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type-1;
      font-weight: 500;

      i {
        margin-left: 0.325rem;
      }
    }
  }

  td,
  th {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;

    img {
      max-width: 100%;
    }

    .badge {
      margin-bottom: 0;
    }
  }

  &.table-borderless {
    border: none;

    td,
    th,
    tr {
      border: none;
    }
  }
}