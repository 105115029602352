/* Dashboard */
.card-statistics {
  .highlight-icon {
    font-size: 3.125rem;
  }

  .highlight-icon-small {
    font-size: 2.5rem;
  }

  p {
    margin-bottom: 0;
  }
}